import { Button } from "components/commons";
import locale from "localization";
import moment from "moment";
export const mapDataToList = ({ billings = {}, onClickView, loading, loadingId }) => {
  const { startDate, endDate, billingId } = billings;

  const formatDate = (date) => {
    return moment(date).format("MMMM D");
  };

  const billingDate = `${formatDate(startDate)} to ${formatDate(endDate)}`;

  return {
    date: billingDate,
    actions: (
      <>
        <Button
          loading={loading && billingId === loadingId}
          disabled={loading && billingId === loadingId}
          link
          onClick={() => onClickView?.(billingId, billingDate)}
        >
          {locale.view}
        </Button>
      </>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
