import { locale } from "localization/en";
import styles from "./non-fuel-business.module.scss";
import { Text, Pill, Product, PopOver } from "components/commons";
import { capitalize, formatAmount } from "utils/text.utils";
import { maskCardNumber } from "utils/number.utils";
import {
  prettifyPaymentStatus,
  prettifyProduct,
  prettifySettlementStatus,
} from "utils/pretty.utils";
import { DateTime, Products, SettlementStatus } from "enums";
import PaymentStatus from "enums/payment-status";
import { formatDate } from "utils";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOverMenu } from "components/commons";
import * as moment from "moment";

export const mapDataToList = ({ merchantPayments = {}, handleVoidPayment }) => {
  const {
    merchantPaymentId,
    stationName,
    stationBusinessName,
    driver,
    vehicle,
    cardNumber,
    paidAt,
    settlementStatus,
    transactionRemarks,
    remarks,
    status,
    amount,
    poNumber,
    orNumber,
    fleet,
    settlementId,
  } = merchantPayments;

  const options = [];

  if (status === PaymentStatus.Success && moment(paidAt).isSame(new Date(), "month")) {
    options.push({
      content: locale.voidTransaction,
      onClick: () =>
        handleVoidPayment({
          merchantPaymentId,
          amount,
          settlementStatus,
          settlementId,
        }),
    });
  }

  return {
    locqpayId: merchantPaymentId,
    gasStationAndBusinessName: (
      <div>
        <Text>{stationName ? `${stationName}` : locale.any}</Text>
        <Text className={styles.subBusinessId}>{stationBusinessName}</Text>
      </div>
    ),
    businessNameID: (
      <div>
        <Text>{fleet?.businessName}</Text>
        <Text className={styles.subBusinessId}>{fleet?.shortName}</Text>
      </div>
    ),
    driversNameAndId: (
      <div>
        <Text>
          {driver?.firstName
            ? `${capitalize(driver?.firstName)} ${capitalize(driver?.lastName)}`
            : locale.any}
        </Text>
        <Text className={styles.subBusinessId}>{driver?.driverLicenseId || locale.any}</Text>
      </div>
    ),
    plateNumber: (
      <div>
        <Text>{vehicle?.plateNumber || locale.any}</Text>
      </div>
    ),
    dateOfTransaction: formatDate(paidAt, DateTime.J),
    fleetCardNo: (
      <div>
        <Text>{cardNumber ? maskCardNumber(cardNumber) : "--"}</Text>
      </div>
    ),

    product: (
      <Product purple={locale.lubes === Products.Lubes}>{prettifyProduct(locale.lubes)}</Product>
    ),
    transactionRemarks: transactionRemarks,
    remarks: remarks ? (
      <PopOver content={<div className={styles.remarks}>{transactionRemarks}</div>}>
        {status === PaymentStatus.Voided && <div className="link">View</div>}
      </PopOver>
    ) : null,
    finalAmountPaid: <div className="nowrap">{`${formatAmount(amount)}`}</div>,
    poNumber: poNumber,
    orNumber: orNumber,
    status: (
      <Pill grass={status === PaymentStatus.Success} cement={status === PaymentStatus.Voided}>
        {prettifyPaymentStatus(status)}
      </Pill>
    ),
    settlementStatus: (
      <div>
        <Pill
          grass={settlementStatus === SettlementStatus.Processed}
          cyan={settlementStatus === SettlementStatus.ForSettlement}
          sky={settlementStatus === SettlementStatus.ForProcessing}
        >
          {prettifySettlementStatus(settlementStatus)}
        </Pill>
        {settlementStatus === SettlementStatus.ForProcessing ||
        settlementStatus === SettlementStatus.Processed ? (
          <Text className={styles.subBusinessId}>{settlementId}</Text>
        ) : (
          <Text className={styles.subBusinessId}>--</Text>
        )}
      </div>
    ),
    action: (
      <>
        {status === PaymentStatus.Success &&
        moment().isBefore(moment(paidAt).add(10, "minutes")) ? (
          <PopOverMenu options={options}>
            <MoreVertIcon className={styles.icon} />
          </PopOverMenu>
        ) : (
          ""
        )}
      </>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { settlementStatuses, status, ...fs } = filterState;
  return {
    ...fs,
    settlementStatuses: !settlementStatuses.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
    status: !status.isSelectedAll ? status.value.join(",") : null,
  };
};
